@import "./main/variables.scss";
@import "./main/mixin.scss";

.partners {
  display: flex;
  min-height: calc(100vh - 250px);
  padding: 30px 0;
  position: relative;

  &__inner {
    display: flex;
    flex-direction: column;
    max-width: 960px;
    margin: 0 auto;
    min-height: 100%;
    gap: 35px;
    justify-content: center;

    h3 {
      color: $black-2;
      font-family: $font-livvic;
      font-style: normal;
      line-height: 1.25;
      text-align: center;
    }
    &-banner {
      position: relative;

      // background
      &:after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 140px;
        z-index: -1;
        border-radius: 5px;
        background: $gradient2;
      }

      h5 {
        padding: 0.4em 1em;

        color: $white;
        text-align: center;
        font-family: $font-chivo;
        font-style: normal;
        font-weight: 400;
        line-height: 1.12;
        letter-spacing: 0.52px;
        text-transform: capitalize;
      }
      &-row {
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 1rem;
        padding: 40px 20px 30px 20px;
        border-radius: 5px;
        background: #fff;
        box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.25);
      }
      &-shape {
        position: relative;
        z-index: -1;
        top: -3px;
        width: 100%;
        left: 0px;
        transform: rotate(0.35deg);
        img {
          width: calc(100% - 5px);
          height: auto;
        }
      }
      &-row,
      &-shape {
        width: calc(100% - 50px);
        margin: 0 auto;
      }
    }
  }
  &-item {
    display: inline-flex;
    height: 38px;
    max-width: 160px;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  .bg {
    filter: blur(162px);
  }
  &--about {
    padding-bottom: 28px;
    min-height: 60vh;
    .partners {
      &__inner {
        padding: 50px 0;
        max-width: 1021px;
        gap: 86px;
        &-banner {
          max-width: 960px;
          margin: 0 auto;
          &-row {
            margin-top: 22px;
            padding: 40px 30px;
            text-align: center;
            h6 {
              max-width: 720px;
              text-transform: capitalize;
            }
          }
          &:after {
            height: 130px;
          }
        }
        &-container {
          display: flex;
          &-item {
            font-size: 24px;
            padding: 1.2em 2em;
            position: relative;
            width: 33.3%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 1.1;
            strong {
              font-size: 0.85em;
              font-weight: 700;
              text-transform: uppercase;
            }
          }
          &-bg {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            pointer-events: none;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}

@include xlg-xxlg {
  .partners {
    &--about {
      .partners {
        &__inner {
          gap: 70px;
          &-container {
            &-item {
              font-size: 22px;
            }
          }
        }
      }
    }
  }
}
@include lg-xlg {
  .partners {
    padding: 60px 0;
    &__inner {
      gap: 40px;

      &-banner {
        max-width: 600px;
        margin: 0 auto;

        // background
        &:after {
          height: calc(100% - 100px);
        }
        h5 {
          padding: 0.6em 1em;
        }
        &-row {
          justify-content: space-around;
          flex-wrap: wrap;
          gap: 2rem;
          padding: 40px 20px;
        }
      }
    }
    &-item {
      height: auto;
      width: calc(33% - 20px);
      height: 55px;

      &._sm {
        height: 45px;
      }
    }
    &--about {
      .partners {
        &__inner {
          gap: 60px;
          &-container {
            &-item {
              font-size: 19px;
              padding: 1.2em 1.5em;
            }
          }
        }
      }
    }
  }
}
@include md-lg {
  .partners {
    &--about {
      .partners {
        &__inner {
          gap: 45px;
          &-container {
            flex-wrap: wrap;
            gap: 30px 0;
            justify-content: center;
            &-item {
              width: 50%;
              min-height: 130px;
              padding: 1.2em 2em;
            }
          }
        }
      }
    }
  }
}
@include sm-md {
  .partners {
    &__inner {
      h3 {
        max-width: 500px;
        margin: 0 auto;
        br {
          display: none;
        }
      }
    }
    &--about {
      .partners {
        &__inner {
          gap: 40px;
          &-banner {
            &-row {
              padding: 30px 25px;
            }
          }
          &-container {
            &-item {
              width: 100%;
              max-width: 400px;
            }
          }
        }
      }
    }
  }
}
@include xsm-sm {
  .partners {
    &-item {
      height: 40px;
      width: 100%;
      &._sm {
        height: 35px;
      }
    }
    &__inner {
      &-banner {
        &-row {
          gap: 30px 15px;
          flex-direction: column;
        }
      }
      h3 {
        max-width: 360px;
        margin: 0 auto;
      }
    }
    &--about {
      padding: 30px 0;
      .partners {
        &__inner {
          &-banner {
            &-row {
              width: calc(100% - 30px);
              margin-top: 15px;
              padding: 25px 20px;
              h6 {
                font-size: 16px;
              }
            }
            &:after {
              height: 110px;
            }
          }
        }
      }
    }
  }
}
@include xxsm-xsm {
  .partners {
    &--about {
      .partners {
        &__inner {
          gap: 40px;
          &-container {
            gap: 20px;
          }
        }
      }
    }
  }
}
