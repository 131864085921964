@import "styles/main/variables.scss";
@import "styles/main/mixin.scss";
.faq {
  padding: 85px 0 154px;
  &__inner {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    &-column {
      flex: 1;
      max-width: 605px;
      display: flex;
      flex-direction: column;
      gap: 34px;
    }
  }
  &__item {
    padding: 24px 32px 26px 38px;
    border-radius: 7px;
    box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
    display: flex;
    flex-direction: column;
    gap: 5px;
    transition: 0.6s ease;
    &-group {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
    }
    &-button {
      position: relative;
      font-size: 20px;
      width: 2em;
      height: 2em;
      border-radius: 8px;
      background: $primary-0;
      transition: 0.2s ease-in;
      border: 1px solid $primary-0;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 1px;
        width: 0.9em;
        height: 2px;
        background: $white;
        transition: inherit;
      }
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 1px;
        width: 2px;
        height: 0.9em;
        background: $white;
        transition: inherit;
        opacity: 0;
      }
      &:hover {
        background: transparent;
        &:before,
        &:after {
          background: $primary-0;
        }
      }
    }
    &-content {
      padding-right: 65px;
      transition: 0.6s ease;
      max-height: 800px;
      overflow: hidden;
    }
    h6 {
      flex: 1;
      font-family: $font-secondary;
      font-weight: 500;
      color: $black;
    }
    p {
      color: $gray-secondary;
      font-family: $font-secondary;
    }

    &._collapse {
      gap: 0;
      transition: 0.3s ease;
      .faq__item {
        &-content {
          max-height: 0;
          transition: 0.3s ease;
        }
        &-button {
          &::after {
            opacity: 1;
          }
        }
      }
    }
  }
  .box {
    max-width: 320px;
    h3 {
      color: $black;
    }
  }
}

// 1024
@include lg-xlg {
  .faq {
    .box {
      max-width: 250px;
    }
  }
}
// 840
@include md-lg {
  .faq {
    .box {
      max-width: 200px;
    }
    &__item {
      padding: 24px;
    }
  }
}
// 700
@include sm-md {
  .faq {
    padding: 60px 0 140px;
    &__inner {
      flex-direction: column;
      &-column {
        max-width: unset;
      }
    }
    .box {
      max-width: unset;
      h3 {
        br {
          display: none;
        }
      }
      p {
        br {
          display: none;
        }
      }
    }
  }
}
// 570
@include xsm-sm {
  .faq {
    &__item {
      &-button {
        font-size: 18px;
      }
    }
  }
}
// 480
@include xxsm-xsm {
  .faq {
    &__inner {
      &-column {
        gap: 20px;
      }
    }
    &__item {
      padding: 20px;
      border-radius: 5px;
      gap: 20px;

      &-content {
        padding-right: 0;
      }
      p {
        font-size: 14px;
      }
    }
  }
}
