@import "styles/main/variables.scss";
@import "styles/main/mixin.scss";

.footer {
  margin-top: auto;
  position: relative;
  z-index: 0;
  &__contact {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 945px;
    margin: 0 auto;
    margin-top: -80px;
    margin-bottom: -45px;
    background: linear-gradient(90deg, #6bf6f5 -14.26%, #a054f7 99.49%);
    border-radius: 18px;
    padding: 35px 40px 40px 40px;
    z-index: 2;
    h6 {
      flex-grow: 1;
      max-width: 630px;

      color: $white;
      font-family: $font-primary;
      font-style: normal;
      font-weight: 700;
      line-height: 1.25;
    }
  }
  &__inner {
    position: relative;
    padding: 95px 0 55px 0;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;

    //bg
    &:after {
      position: absolute;
      content: "";
      top: 0;
      left: 50%;
      width: 100vw;
      height: 100%;
      background: $black-secondary;
      transform: translate(-50%);
      pointer-events: none;
      z-index: -1;
    }

    color: $white;
    h6 {
      color: $white;
      font-family: $font-primary;
      font-style: normal;
      font-weight: 700;
      line-height: 1.2;
    }

    &-info {
      display: flex;
      flex-direction: column;
      gap: 14px;
      width: 185px;
      margin-right: auto;

      p {
        color: $white;
        font-family: $font-primary;
        font-style: normal;
        font-weight: 300;
        font-size: 10px;
        line-height: 1.6;
        text-transform: capitalize;
      }
      &-social {
        display: flex;
        gap: 12px;
      }
    }
    &-links {
      display: flex;
      justify-content: space-around;
      flex-grow: 1;

      &-column {
        display: flex;
        flex-direction: column;
        gap: 15px;
      }
    }
    &-news {
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: 35%;
      max-width: 370px;
      .input {
        font-size: 14px;

        input {
          padding: 1.1em 12em 1.1em 1.5em;
        }
        &__wrapper {
          .buttonPrimary {
            right: 11px;
            padding: 0.6em 1.15em;
            min-width: 145px;
            box-shadow: unset;
          }
        }
      }
      h6 {
        text-align: center;
      }
    }
  }
  &__copyright {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    padding: 15px 0;
    gap: 5px;

    color: $white;
    font-family: $font-primary;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1;
    text-align: center;

    //bg
    &:after {
      position: absolute;
      content: "";
      top: 0;
      left: 50%;
      width: 100vw;
      height: 100%;
      background: $secondary;
      transform: translate(-50%);
      pointer-events: none;
      z-index: -1;
    }
  }
  &-link {
    color: $white;
    font-family: $font-primary;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    transition: 0.2s ease-out;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
    &._lg {
      font-weight: 700;
      color: $white;
      font-size: 20px;
      opacity: 1;
    }
  }
}

@include xlg-xxlg {
  .footer {
    &__inner {
      row-gap: 40px;
      &-info {
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        gap: 15px;

        &-social {
          width: 100%;
        }

        p {
          font-size: 12px;
          max-width: 360px;
          padding-left: 1.5em;
          border-left: 1px solid rgba($white, 0.2);
        }
      }
      &-links {
        justify-content: space-between;
        width: calc(100% - 400px);
        max-width: 500px;
        flex-grow: unset;
      }
      &-news {
        width: 375px;
      }
    }
  }
}
@include md-lg {
  .footer {
    &__contact {
      padding: 25px 20px;
      flex-direction: column;
      gap: 20px;
      max-width: 700px;
      text-align: center;
    }
    &__inner {
      flex-direction: column;
      align-items: center;
      gap: 50px;

      &-info {
        flex-direction: column;
        width: unset;
        margin: unset;
        text-align: center;

        p {
          padding: 0;
          border: 0;
        }
        &-social {
          justify-content: center;
        }
      }

      &-news {
        padding: 35px 25px;
        text-align: center;
        align-items: center;
        width: 100%;
        max-width: 500px;
        border-top: 1px solid rgba($white, 0.1);
        border-bottom: 1px solid rgba($white, 0.1);
      }
      &-links {
        order: 1;
        width: 100%;
        &-column {
          text-align: center;
        }
      }
    }
    &-link {
      &._lg {
        font-size: 18px;
      }
    }
  }
}
@include sm-md {
  .footer {
    &__contact {
      h6 {
        max-width: 500px;
        display: flex;
        flex-direction: column;
      }
      .button {
        &Primary {
          &--white {
            color: $white;
            background: $primary;
            &:hover {
              color: $primary;
              background: $white;
            }
          }
        }
      }
    }
  }
}
@include xxsm-xsm {
  .footer {
    &__inner {
      &-news {
        padding: 35px 0;
      }
    }
    &__copyright {
      margin: 0 -10px;
    }
  }
}
