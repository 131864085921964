@import "styles/main/mixin.scss";
@import "styles/main/variables.scss";

.nav {
  flex-grow: 1;
  &__inner {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    &-links {
      display: flex;
      align-items: center;
      gap: 1.8em;
    }
    &-buttons {
      display: flex;
      align-items: center;
    }
  }
  &-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;

    color: $black-2;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 1;
    transition: all 0.3s ease;

    font-family: $font-primary;
    padding: 0.7em 0.25em;
    border-radius: 3.5em;

    position: relative;

    &::after {
      content: "";
      width: 100%;
      height: 3px;
      border-radius: 3px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: $gray-2;
      transform: scale(0);
      transition: 0.3s ease-out;
    }
    &:hover {
      opacity: 0.8;
      &::after {
        opacity: 0.2;
        transform: scale(1);
      }
    }
    &.active {
      &::after {
        width: 100%;
        background: $gradient;
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}

@include xlg-xxlg {
  .nav {
    &__inner {
      &-links {
        gap: 1.3em;
      }
    }
  }
}

@include lg-xlg {
  .nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($black, 0.4);
    backdrop-filter: blur(4px);
    z-index: 0;
    transform: translate(100%);
    opacity: 0;
    pointer-events: none;
    transition: all 0s ease 0.25s, opacity 0.2s ease-in-out 0s;

    &__inner {
      position: absolute;
      top: 0;
      left: 0;
      width: 35%;
      min-width: 280px;
      height: 100%;

      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 30px;

      background: $white;
      box-shadow: 0px 4px 24px -1px rgba($black, 0.2);
      padding: 50px 25px;
      transform: translate(-100%);
      opacity: 0;
      transition: 0.15s ease-in-out 0s;

      &-buttons {
        flex-direction: column;
        align-items: flex-start;
        padding: 25px 0;
        gap: 8px;
        color: $black;
        width: 100%;

        .buttonPrimary--white {
          border: 1px solid $primary;

          &:active {
            box-shadow: 0 0 0 2px $primary;
            box-shadow: 0 0 5px 2px rgba($black, 0.2);
          }
        }
      }
      &-links {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }
    &-link {
      position: relative;
      color: $black;
      padding: 0.4em 1em;

      &:after {
        left: 0;
        top: 50%;
        width: 4px;
        height: 80%;
        transform: translateY(-50%) !important;
        opacity: 0.2;
        border-radius: 0 !important;
      }

      &:hover {
        &:after {
          height: 100%;
        }
      }
      &.active {
        &:after {
          width: 4px;
        }
      }
    }
    &.active {
      pointer-events: all;
      transform: translate(0);
      opacity: 1;
      transition: opacity 0.3s ease-in-out;

      .nav__inner {
        opacity: 1;
        transition: 0.3s ease-in-out 0.05s;
        transform: translate(0);
      }
    }
  }
}
