:root {
  height: 100%;
}
html {
  height: 100%;
  scroll-behavior: smooth;
}
* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
  box-sizing: border-box;
}
button {
  cursor: pointer;
  outline: none;
  background: transparent;
  &:focus {
    outline: none;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba($color: #000, $alpha: 0.2);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba($color: #000, $alpha: 0.3);
}

body {
  height: 100%;
  cursor: default;
  font-size: 16px;
  color: $black;
  line-height: 1.6em;
  font-weight: 400;
  font-family: $font-primary;
  background: $white;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}
body.active {
  overflow: hidden;
}
a {
  cursor: pointer;
  text-decoration: none;
  color: #000;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

img,
svg {
  pointer-events: none;
}
.main {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 100%;
}
