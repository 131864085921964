@import "./main/variables.scss";
@import "./main/mixin.scss";

@keyframes carousel {
  from {
    transform: translate(150%, -50%);
  }
  to {
    transform: translate(-150%, -50%);
  }
}

.customers {
  padding: 30px 0;
  background: $black-secondary;

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 18px;
    align-items: center;
    &-slider {
      width: 100%;

      .slick {
        &-slide {
          width: auto;
        }
        &-track {
          padding: 10px 0;
        }
      }
    }
    &-title {
      padding: 0 16px;
      max-width: 900px;
      margin: 0 auto;
    }
    h6 {
      color: $white-2;
      font-family: $font-chivo;
      font-style: normal;
      font-weight: 400;
      line-height: 1.12;
      text-align: center;
      letter-spacing: 0.02em;
    }
    &-row {
      display: flex;
      justify-content: space-between;
      gap: 15px;
      padding: 15px 0;
      width: 100%;
      max-width: 1050px;
    }
  }
  &-item {
    position: relative;
    display: inline-flex;
    height: 38px;
    width: 200px;
    pointer-events: none;

    img,
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 100%;
      width: 100%;
      object-fit: contain;
      transform: translate(-50%, -50%);
    }

    &._lg {
      img,
      svg {
        height: 120%;
        width: 120%;
      }
    }
    &._xlg {
      img,
      svg {
        height: 160%;
        width: 130%;
      }
    }
  }

  &--lg {
    .autocontainer {
      max-width: 1920px;
      padding: 0;
    }
  }
  &--about {
    .customers {
      &__inner {
        gap: 15px;
        &-row {
          align-items: center;
          padding: unset;
        }
      }
      &-item {
        height: 54px;
        &--sm {
          height: 33px;
        }
      }
    }
    h5 {
      color: $white;
      span {
        background: var(
          --Grad-1,
          linear-gradient(267deg, #6bedf6 10.47%, #9e59f7 97.78%)
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}
.carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
  width: 100vw;
  font-size: 18px;
  height: 3em;

  &-item {
    padding: 0 55px;
    height: 1.6em;
    box-sizing: content-box;

    img {
      height: 100%;
      width: auto;
      object-fit: contain;
    }

    &._lg {
      font-size: 1.2em;
    }
    &._xlg {
      font-size: 1.6em;
    }
  }
  &-track {
    position: absolute;
    display: flex;
    align-items: center;
    top: 50%;
    left: 50%;
    height: 100%;
    transform: translateX(100%);
    animation: carousel 96s linear infinite;
    // animation-play-state: paused;
    will-change: transform;

    &:nth-child(2) {
      animation-delay: 32s;
    }
    &:nth-child(3) {
      animation-delay: -64s;
    }
    &:nth-child(4) {
      animation-delay: -32s;
    }
  }
}

@include xlg-xxlg {
  .customers {
    &-item {
      width: 100%;
    }
    &--about {
      .customers {
        &-item {
          height: 50px;
          &--sm {
            height: 30px;
          }
        }
      }
    }
  }
}
@include lg-xlg {
  .customers {
    padding: 20px 0;
    &-item {
      height: 34px;
    }
    &--about {
      .customers {
        &__inner {
          gap: 16.5px;
        }
        &-item {
          height: 42px;
          &--sm {
            height: 27px;
          }
        }
      }
    }
  }
}
@include md-lg {
  .customers {
    &__inner {
      &-row {
        padding: 10px 0;
      }
      h6 {
        font-size: 16px;
      }
    }
    &--about {
      .customers {
        &__inner {
          gap: 18px;
        }
        &-item {
          height: 35px;
          &--sm {
            height: 22px;
          }
        }
      }
    }
  }
}
@include sm-md {
  .customers {
    .slick {
      &-slide {
        padding: 0 1.5em;
        width: unset;
      }
    }
    &-item {
      height: 27px;
    }
    &--about {
      .customers {
        &__inner {
          gap: 18px;
        }
        &-item {
          height: 27px;
          width: 160px;

          &--sm {
            height: 19px;
          }
        }
      }
    }
  }
}
@include xsm-sm {
  .customers {
    padding: 30px 0;
    &-item {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &__inner {
      gap: 25px;

      h6 {
        font-size: 20px;
        color: $white;
        line-height: 1.3;
      }
      &-row {
        order: 1;
        flex-wrap: wrap;
        justify-content: space-evenly;
        gap: 30px 10px;
        .customers-item {
          height: 35px;
          width: calc(50% - 10px);
        }
      }
      &-title {
        order: -1;
      }
    }
    &--about {
      padding: 40px 0;

      .customers {
        &__inner {
          gap: 30px;
          &-row {
            flex-direction: column;
            .customers {
              &-item {
                width: 100%;
                height: 40px;
                &--sm {
                  height: 30px;
                }
              }
            }
          }
        }
      }
    }
    .slick {
      &-slide {
        padding: 0 1em;
        width: unset;
      }
    }
  }
  .carousel {
    font-size: 15px;
    &-item {
      padding: 0 40px;
    }
  }
}
@include xxsm-xsm {
  .customers {
    &-item {
      height: 25px;
    }
    .slick {
      &-slide {
        padding: 0 0.75em;
        width: unset;
      }
    }
  }
  .carousel {
    font-size: 13px;

    &-item {
      padding: 0 30px;
    }
  }
}
