@import "./main/variables.scss";
@import "./main/mixin.scss";

.input {
  display: flex;
  width: 100%;
  font-size: 14px;

  input,
  textarea,
  .input__selected {
    padding: 0.6em 1.3em;
    border-radius: 1.57em;
    width: 100%;
    background-color: $white;
    border: 1px solid $white;
    resize: none;
    transition: 0.3s ease-out;

    //font
    color: $black;
    font-family: $font-primary;
    font-style: normal;
    font-weight: 400;
    font-size: inherit;
    line-height: 1;
    &::placeholder {
      color: $gray-secondary;
    }
  }
  input:focus,
  textarea:focus {
    border-color: $primary;
  }
  &--rounded {
    font-size: 16px;
    input,
    .input__selected {
      border-radius: 3.75em;
      border-color: $white-3;
      padding: 1.3em 12em 1.3em 1.5em;
      font-family: $font-secondary;

      &:focus {
        border-color: $primary;
      }
    }
  }
  &--border {
    input {
      border-color: $white-5;
      border-radius: 7px;
      padding: 0.75em 1em;
    }
  }
  &--select {
    position: relative;
    z-index: 2;
    .input {
      &__selected {
        &:after {
          color: inherit;
          content: "";
          position: absolute;
          display: block;
          top: 50%;
          right: 1.2em;
          width: 0;
          height: 0;
          border-bottom: 6px solid #a3a3a3;
          border-right: 6px solid transparent;
          border-left: 6px solid transparent;
          transform: translateY(-50%) rotate(180deg);
          transition: 0.3s ease;
        }
      }
      &__dropdown {
        position: absolute;
        top: calc(100% + 5px);
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        background: $white;
        border-radius: 5px;
        opacity: 0;
        pointer-events: none;
        transition: 0.3s linear;
        border: 1px solid $white-2;
        &-item {
          color: $black;
          padding: 10px;
          border-bottom: 1px solid $white-2;
          transition: 0.3s linear;
          cursor: pointer;
          &:hover {
            opacity: 0.7;
          }
          &:last-child {
            border-bottom: unset;
          }
        }
      }
    }
    &.active .input {
      &__selected {
        &:after {
          transform: translateY(-50%) rotate(0);
        }
      }
      &__dropdown {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
  &._shadow {
    input {
      box-shadow: 0px 8px 25px 0px rgba(13, 10, 44, 0.06);
    }
  }
  &__wrapper {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    label {
      color: $black-3;
      font-weight: 400;
      font-size: 14px;
    }

    .buttonPrimary {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.button {
  &:active {
    opacity: 0.7;
  }
  &:disabled {
    cursor: default;
    opacity: 0.5 !important;
  }

  &Primary {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;

    color: $black-2;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 1;
    transition: all 0.3s ease;

    font-family: $font-secondary;
    padding: 0.7em 1.35em;
    border-radius: 3.5em;

    &--default {
      background: $primary;
      color: $white;
      &:hover {
        background: $white;
        border-color: $primary;
        color: $primary;
      }
    }
    &--white {
      background: $white;
      color: $primary;
      &:hover {
        border-color: $primary;
      }
    }
    &._shadow {
      box-shadow: 0px 3px 12px 0px rgba(74, 58, 255, 0.18);
    }
    &._lg {
      font-size: 18px;
      padding: 0.86em 1.72em;
      font-weight: 400;
    }
  }

  &Ico {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.7em;
    height: 1.7em;

    color: $white;
    font-size: 18px;
    box-shadow: 0 0 0 transparent;

    transform: scale(1);
    transition: all 0.3s ease;
    border-radius: 50%;
    background: $primary;

    svg,
    img {
      pointer-events: none;
      color: inherit;
      width: 0.9em;
      height: 0.9em;
      object-fit: contain;
    }

    &:hover {
      box-shadow: 0 0 0 3px rgba($white, 0.16);
      transform: scale(1.1);
    }
    &:active {
      transform: scale(1);
      box-shadow: 0 0 0 2px rgba($white, 0.8);
    }
    &--ico {
      background: transparent;
      width: 1.25em;
      height: 1.25em;
      svg,
      img {
        width: 100%;
        height: 100%;
      }
      &:hover {
        color: $primary;
        background: transparent;
      }
    }
  }

  &Text {
    font-size: 16px;
    color: #2f6cde;
    font-family: $font-secondary;
    font-weight: 700;
    position: relative;
    transition: 0.2s ease;
    &::after {
      content: "";
      width: 100%;
      height: 1px;
      background: currentColor;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    &:hover {
      color: $primary;
    }
  }
}
.ratioImage {
  position: relative;
  padding-bottom: 65%;
  width: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &--contain {
    img {
      object-fit: contain;
    }
  }
}
.logo {
  font-size: 20px;
  width: 7.25em;
  height: 2.9em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @include transition;
  img {
    width: 100%;
    object-fit: contain;
  }
}
.ico {
  font-size: 14px;
  width: 1em;
  height: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  @include transition;
  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }
  &--stroke {
    svg {
      fill: transparent;
      stroke: currentColor;
    }
  }
}
.box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
  color: $black-2;
  text-align: center;
  h3 {
    font-family: $font-livvic;
  }
  h5 {
    font-family: $font-secondary;
    text-transform: uppercase;
  }
  h6 {
    font-family: $font-secondary;
    text-transform: capitalize;
  }
  &--secondary {
    text-align: left;
    gap: 10px;
    h6 {
      font-family: $font-secondary;
    }
    h3 {
      font-family: $font-secondary;
    }
    p {
      color: $gray-4;
    }
  }
}
.text {
  &--title {
    font-family: $font-livvic;
  }
  &--secondary {
    font-family: $font-secondary;
  }
  &--line {
    position: relative;
    padding-left: 32px;
    color: $primary;
    &::before {
      content: "";
      font-size: inherit;
      width: 22px;
      height: 2px;
      background: currentColor;
      border-radius: 2px;
      position: absolute;
      left: 0;
      top: 0.5em;
    }
  }
  &--gradient {
    background: $gradient;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.list {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-family: $font-chivo;
  color: $gray-3;
  line-height: 1.2;
  gap: 10px;
  &__item {
    position: relative;
    padding-left: 14px;
    &::before {
      content: "";
      width: 7px;
      height: 7px;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 5px;
      background: $primary;
    }
  }
  &--gradient {
    .list {
      &__item {
        &::before {
          background: linear-gradient(142deg, #9c61f7 14.84%, #6ee4f6 81.58%);
        }
      }
    }
  }
  &--secondary {
    .list {
      &__item {
        &::before {
          background: $white;
        }
      }
    }
  }
  &--gray {
    color: #a1a1a1;
    font-size: 16px;
    .list {
      &__item {
        &::before {
          top: 7px;
          width: 5px;
          height: 5px;
          background: #a1a1a1;
        }
      }
    }
  }
}
.example {
  background: $black-secondary;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;

  &__image {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &Border {
    font-size: 20px;
    padding: 0.9em;
    position: relative;
    &__shape {
      font-size: inherit;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
      width: 5em;
      height: 5em;
      &::after {
        content: "";
        font-size: inherit;
        width: 1em;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(270deg, #9575f7 -100.8%, #70e1f6 99.19%);
      }
      &::before {
        content: "";
        font-size: inherit;
        width: 100%;
        height: 20px;
        position: absolute;
        left: 0;
        bottom: 0;
        background: linear-gradient(91deg, #9575f7 -15.37%, #70e1f6 214.03%);
      }
      &._top {
        left: unset;
        bottom: unset;
        top: 0;
        right: 0;
        transform: rotate(180deg);
      }
    }
  }
}
.badge {
  border-radius: 100px;
  padding: 0.3em 0.5em;

  color: $black;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 1;
}
.checkbox {
  display: inline-flex;
  position: relative;
  font-size: 18px;
  z-index: 0;

  input {
    width: 1em;
    height: 1em;
    z-index: 1;
    font-size: inherit;
    opacity: 0;

    &:checked + span::before {
      border-color: $black;
    }
  }
  span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &::after {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1.5px solid $black;
      box-sizing: border-box;
    }
    &::before {
      content: "";
      position: absolute;
      display: block;
      top: 40%;
      left: 50%;
      width: 0.25em;
      height: 0.5em;
      border-bottom: 1.5px solid rgba($black, 0.2);
      border-right: 1.5px solid rgba($black, 0.2);
      transition: 0.1s ease;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  &__outer {
    display: flex;
    align-items: center;

    label {
      color: $black;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.4;
      margin-left: 0.7em;

      a {
        color: inherit;
        font-style: normal;
        font-weight: 400;
        font-size: inherit;
        text-decoration: underline;
        transition: 0.3s ease;
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}
.custIcon {
  position: relative;
  display: inline-block;
  font-size: 16px;
  height: 0.8em;
  width: 0.8em;
  color: inherit;

  &--left {
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 75%;
      height: 75%;
      background: transparent;
      border-left: 2px solid currentColor;
      border-bottom: 2px solid currentColor;
      transform: translate(-25%, -50%) rotate(45deg);
    }
  }
  &--rtArr {
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 75%;
      height: 75%;
      background: transparent;
      border-right: 2px solid currentColor;
      border-top: 2px solid currentColor;
      transform: translate(-50%, -50%);
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 2px;
      background: currentColor;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
  &--right {
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 75%;
      height: 75%;
      background: transparent;
      border-right: 2px solid currentColor;
      border-top: 2px solid currentColor;
      transform: translate(-75%, -50%) rotate(45deg);
    }
  }
  &--up {
    &::before {
      content: "";
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      border-bottom: 5px solid #7b8a8a;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease-in-out;
    }
  }
  &--down {
    &::before {
      content: "";
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      border-top: 5px solid #7b8a8a;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease-in-out;
    }
  }
  &--cross {
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 2px;
      height: 100%;
      background: $black;
    }
    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
  &--tick {
    margin-right: 5px;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 45%;
      height: 80%;
      border-bottom: 2px solid $black;
      border-right: 2px solid $black;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
  &--dots {
    font-size: 5px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    background: $black;
    transition: 0.2s ease-in;
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      background: inherit;
      transition: 0.2s ease-in;
    }
    &:before {
      left: calc(100% + 0.75em);
    }
    &:after {
      right: calc(100% + 0.75em);
    }
    &.active {
      width: 4.5em;
      border-radius: 2px;
      &:before,
      &:after {
        opacity: 0;
      }
    }
  }
  &--rounded {
    font-size: inherit;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    background: $primary;

    &:after {
      top: 45%;
      width: 25%;
      height: 50%;
      border-color: $black;
    }
  }
}
.noBreak {
  white-space: nowrap;
}
.stepper {
  display: flex;
  align-items: flex-start;
  position: relative;
  &__progress {
    width: 96px;
    height: 45px;
    position: absolute;
    top: 0;
    left: 88px;
    &-bg {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background: $white;
      width: 100%;
      height: 2px;
      z-index: -1;
    }
    &-line {
      position: absolute;
      top: 50%;
      width: 0;
      height: 2px;
      background: $primary;
      transition: 0.3s ease;
      transform: translateY(-50%);
    }
  }
  &__item {
    font-size: 18px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    min-width: 150px;
    position: relative;
    &-progress {
      position: relative;
      z-index: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      @include transition;

      &:before,
      &:after {
        position: absolute;
        content: "";
        top: 50%;
        left: 0;
        width: 50%;
        height: 2px;
        transform: translateY(-50%);
        background: $white;
        z-index: -1;
      }
      &:before {
        right: 50%;
      }
      &:after {
        left: 50%;
      }
    }

    &:hover {
      .stepper {
        &-icon {
          // color: $orange;
          // border-color: $orange;
        }
      }
    }
    &.done {
      .stepper {
        &-icon {
          color: $white;
          background: $primary;
          border-color: transparent;
        }
      }
      .stepper__item-progress {
        &:after {
          background: $primary;
        }
      }
      & + .stepper__item > .stepper__item-progress {
        &::before {
          background: $primary;
        }
      }
    }
    &.active {
      .stepper {
        &-title {
          color: $primary;
        }
        &-icon {
          color: $primary;
          background: $white;
          border-color: $primary;
        }
      }
    }
    &:first-child .stepper__item-progress {
      &::before {
        display: none;
      }
    }
    &:last-child .stepper__item-progress {
      &::after {
        display: none;
      }
    }
  }
  &-icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    background: $white;
    padding: 10px;
    border: 1px solid transparent;
    box-shadow: 0 0 0 4px $white;
    @include transition;

    // color
    color: $primary;
    font-size: 0.9em;
    color: $black;
    font-weight: 400;
  }
  &-title {
    color: rgba($primary, 0.75);
    font-size: inherit;
    font-weight: 400;
    padding: 0 15px;
    text-align: center;
    @include transition;
  }
}
.fstars {
  display: flex;
  align-items: center;
  font-size: 21px;
  gap: 0.3em;

  &-star {
    width: 1em;
    color: $white-5;

    svg {
      width: 100%;
      height: auto;
    }
    &._fill {
      color: $yellow;
    }
  }
}
.spinner {
  font-size: 20px;
  color: $primary;
  display: inline-block;
  position: relative;
  width: 4em;
  height: 4em;
  & div {
    position: absolute;
    border: 4px solid currentColor;
    opacity: 1;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    animation: spinner 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  & div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes spinner {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    4.9% {
      transform: scale(0);
      opacity: 0;
    }
    5% {
      transform: scale(0);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 25vh;
    padding-bottom: 5vh;
  }
}

.slick {
  &-track {
    will-change: auto;
  }
  &-slide {
    height: unset !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2em;
    min-width: 140px;
  }
}
