@import "styles/main/mixin.scss";
@import "styles/main/variables.scss";

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 0;
  z-index: 5;
  transition: 0.3s ease;
  &__inner {
    position: relative;
    display: flex;
    align-items: center;
    gap: 60px;
    z-index: 2;

    .burger {
      display: none;
    }
  }
  &--sticky {
    position: fixed;
    background: $white;
    padding: 20px 0;
    box-shadow: 0 2px 10px rgba($black-secondary, 0.05),
      0 2px 4px rgba($black-secondary, 0.15);

    .buttonPrimary {
      &._shadow {
        background: $primary;
        box-shadow: unset;
        color: $white;
        &:hover {
          background: $white;
          color: $primary;
        }
      }
    }
  }
  &:not(._white) + section {
    padding-top: 90px;
  }
  &._white {
    background: $white;
    & + section {
      margin-top: 90px;
    }
  }
  .logo {
    font-size: 17px;
  }
  .buttonPrimary--white {
    &._shadow {
      box-shadow: unset;
    }
  }
  .buttonPrimary {
    &._shadow {
      box-shadow: unset;
    }
  }
}
.burger {
  cursor: pointer;
  display: block;
  position: relative;
  font-size: 10px;
  width: 2.6em;
  height: 2em;
  color: $black-secondary;
  background: transparent;
  transition: 0.2s ease;

  span,
  &::after,
  &::before {
    position: absolute;
    left: 50%;
    width: 100%;
    height: 0.32em;
    background-color: currentColor;
    border-radius: 0.4em;
    transition: all 0.3s ease;
  }
  span {
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &::before {
    content: "";
    top: 0;
    transform: translateX(-50%);
  }
  &::after {
    content: "";
    bottom: 0;
    transform: translateX(-50%);
  }

  &:hover {
    opacity: 0.8;
  }

  &.active {
    color: $white;

    &::before {
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &::after {
      bottom: 50%;
      transform: translate(-50%, 50%) rotate(-45deg);
    }
    span {
      width: 0;
      opacity: 0;
      transition: 0.2s ease;
    }
  }
}

//1180px
@include xlg-xxlg {
  .header {
    &__inner {
      gap: 30px;
    }
  }
}
// 1024px
@include lg-xlg {
  .header {
    padding: 15px 0;
    &__inner {
      justify-content: space-between;

      .burger {
        display: block;
        z-index: 2;
      }
    }
    &:not(._white) + section {
      padding-top: 80px;
    }
    &._white {
      & + section {
        margin-top: 80px;
      }
    }
    &--sticky {
      padding: 15px 0;
    }
  }
}
// 700px
@include sm-md {
  .header {
    padding: 15px 0;
    &__inner {
      &-logo {
        .logo {
          font-size: 16px;
        }
      }
    }
    &:not(._white) + section {
      padding-top: 76px;
    }
    &._white {
      & + section {
        margin-top: 76px;
      }
    }
    &--sticky {
      padding: 10x 0;
    }
  }
}
// 570px
@include xsm-sm {
  .header {
    padding: 13px 0;

    &__inner {
      &-logo {
        .logo {
          font-size: 14px;
        }
      }
    }
    &:not(._white) + section {
      padding-top: 66px;
    }
    &._white {
      & + section {
        margin-top: 66px;
      }
    }
  }
}
