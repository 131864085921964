@mixin noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

@mixin hideScroll {
  scrollbar-width: none; /* Firefox 64 */
  -ms-overflow-style: none; /* Internet Explorer 11 */
  &::-webkit-scrollbar {
    /** WebKit */
    display: none;
  }
}

@mixin ellipsis($line: 3) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin ellipsisLine($line: 3) {
  -webkit-line-clamp: $line;
}

@mixin contentAbsolute {
  content: "";
  display: block;
  position: absolute;
}
@mixin content-bg {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

@mixin transition {
  transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
}
@mixin transition-bg {
  transition: background 0.2s ease-in-out 0s;
  -moz-transition: background 0.2s ease-in-out 0s;
}
@mixin transition-border {
  transition: border 0.2s ease-in-out 0s;
  -moz-transition: border 0.2s ease-in-out 0s;
}
@mixin transition-color {
  transition: color 0.2s ease-in-out 0s;
  -moz-transition: color 0.2s ease-in-out 0s;
}
@mixin transition-opacity {
  transition: opacity 0.2s ease-in-out 0s;
  -moz-transition: opacity 0.2s ease-in-out 0s;
}

@mixin fullImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}
@mixin containImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
  -o-object-fit: contain;
}

@mixin transformY {
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
@mixin transformX {
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}
@mixin transform {
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
@mixin transformReset {
  transform: translate(0, 0);
  -moz-ransform: translate(0, 0);
  -o-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
}

@mixin rotate {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
}
@mixin rotate180deg {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
}
@mixin rotate270deg {
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
}

@mixin inDarkMode {
  html[data-theme="dark"] & {
    @content;
  }
}

@mixin xxsm-xsm {
  @media (max-width: 480px) {
    @content;
  }
}
@mixin xsm-sm {
  @media (max-width: 570px) {
    @content;
  }
}
@mixin sm-md {
  @media (max-width: 700px) {
    @content;
  }
}
@mixin md-lg {
  @media (max-width: 840px) {
    @content;
  }
}
@mixin lg-xlg {
  @media (max-width: 1024px) {
    @content;
  }
}
@mixin xlg-xxlg {
  @media (max-width: 1180px) {
    @content;
  }
}
@mixin xxlg-xxxlg {
  @media (max-width: 1280px) {
    @content;
  }
}

