$primary: #966df9;
$primary-0: #956df8;
$primary-2: #a357fa;
$secondary: #343478;
$secondary-2: #333378;
$black: #000;
$black-2: #000;
$black-3: #0e0523;
$black-secondary: #170f49;
$black-secondary2: #170f49;
$black-secondary3: #1e1e51;
$white: #fff;
$white-0: #f7f7f7;
$white-2: #cfd1d8;
$white-3: #eff0f6;
$white-4: #d9d9d9;
$white-5: #dddddd;
$gray: #525252;
$gray-1: #d9d9d9;
$gray-2: #8e8e8e;
$gray-3: #525252;
$gray-4: #808080;
$gray-5: #373737;
$gray-secondary: #6f6c90;

$mint: #6bedf6;
$mint2: #7bc7f7;
$yellow: #ffd029;

$font-primary: "Inter", sans-serif;
$font-secondary: "DM Sans", sans-serif;
$font-poppins: "Poppins", sans-serif;
$font-chivo: "Chivo", sans-serif;
$font-chonburi: "Chonburi", serif;
$font-marope: "Manrope", sans-serif;
$font-livvic: "Livvic", sans-serif;
$font-fredoka: "Fredoka", sans-serif;

$gradient: linear-gradient(267deg, $mint 10.47%, #9e59f7 97.78%);
$gradient2: linear-gradient(98deg, $mint -11.32%, #a057f8 70.11%);
