@import "./main/variables.scss";
@import "./main/mixin.scss";

.ourprocess {
  position: relative;
  min-height: calc(100vh - 100px);
  padding: 70px 0 50px 0;

  &__inner {
    color: $white;
    h2 {
      text-align: center;
      text-transform: uppercase;
    }
    &-header {
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 72px;
      h5 {
        color: $black;
      }
    }
  }
  &__banner {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    z-index: 0;
    padding-top: 70px;
    padding-bottom: 30px;

    // &:after {
    //   position: absolute;
    //   content: "";
    //   bottom: 30px;
    //   left: 25%;
    //   width: 12%;
    //   transform: translate(-55%, -50%);
    //   border-radius: 50%;
    //   box-shadow: 0 0 20px 15px rgba(0, 0, 0, 0.2),
    //     0 0 15px 5px rgba(0, 0, 0, 0.4);
    // }
    &-item {
      position: relative;
      padding-left: 60%;
      min-height: 130px;

      &-image {
        position: absolute;
        left: 25%;
        top: 7%;
        height: 120%;
        transform: translate(-55%, -50%);
        display: flex;

        img {
          height: 100%;
          width: auto;
        }
      }
      &-line {
        position: absolute;
        display: block;
        top: 13px;
        right: 41.5%;
        width: 9%;
        height: 0;
        color: $primary-0;
        border-bottom: 2px dashed currentColor;

        &:before,
        &:after {
          position: absolute;
          content: "";
          top: 50%;
          width: 8.5px;
          height: 8.5px;
          background: currentColor;
          transform: translate(-50%, -50%) rotate(45deg);
        }
        &:after {
          left: 100%;
        }
        &:before {
          left: 0;
        }
      }
      &-content {
        display: flex;
        flex-direction: column;
        gap: 0.35em;
        font-size: 16px;

        h5 {
          color: $white;
          font-family: $font-chivo;
          font-style: normal;
          font-weight: 900;
          line-height: 1.2;
          letter-spacing: 0.02em;
          text-transform: capitalize;
          margin-bottom: 10px;
        }
        span {
          color: $white;
          font-family: $font-chivo;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 1.2;
          letter-spacing: 0.02em;
          text-transform: capitalize;
        }
        ol {
          display: flex;
          flex-wrap: wrap;
          align-items: baseline;
          width: 100%;
          max-width: 330px;
          gap: 0.5em 30px;
          &._lg {
            width: auto;
            li {
              width: auto;
            }
          }
          li {
            position: relative;
            width: calc(50% - 15px);
            padding-left: 1em;
            &:after {
              position: absolute;
              content: "";
              top: 50%;
              left: 0;
              width: 0.55em;
              height: 0.55em;
              border-radius: 50%;
              transform: translateY(-50%);
              background: $primary;
              background: linear-gradient(
                141.84deg,
                #9c61f7 14.84%,
                #6ee4f6 81.58%
              );
            }
          }
        }
      }
      @for $i from 1 through 4 {
        &:nth-child(#{$i}) {
          z-index: #{4 - $i};

          .ourprocess__banner-item-line {
            width: #{(11 + ($i * 2.5)) * 1%};
          }
        }
      }
    }
  }
  &-group {
    display: flex;
    align-items: center;
    gap: 24px;

    a {
      display: flex;
      height: 42px;
      width: auto;
      transition: 0.2s ease-out;
      pointer-events: none;
      img {
        height: 100%;
        width: auto;
      }
      &:active {
        opacity: 0.6;
      }
    }
  }
  .bg {
    background: $black-secondary;
    &__image {
      width: 100%;
      height: 100%;
      top: 0;
      img {
        object-fit: cover;
      }
    }
  }
  &--home {
    background: $white-4;
    min-height: unset !important;
    padding: 100px 0 50px 0;

    .ourprocess {
      &__inner {
        &-header {
          h2 {
            text-transform: capitalize;
          }
          h5 {
            text-transform: uppercase;
          }
        }
      }
      &__banner {
        &-item {
          &-content {
            h5 {
              color: $black;
            }
            span {
              color: $black;
            }
          }
        }
        &:after {
          opacity: 0.25;
        }
      }
    }
    h2 {
      color: $black;
    }
    .bg {
      display: none;
    }
  }
}
// max-width: 1180px
@include xlg-xxlg {
  .ourprocess {
    &__banner {
      &-item {
        padding-left: 55%;

        &-line {
          right: 46.8%;
        }
        @for $i from 1 through 4 {
          &:nth-child(#{$i}) {
            .ourprocess__banner-item-line {
              width: #{(3 + ($i * 3.2)) * 1%};
            }
          }
        }
      }
    }
  }
}
// max-width: 1024px
@include lg-xlg {
  .ourprocess {
    &__banner {
      &-item {
        padding-left: 35%;

        &-image {
          left: 0;
        }
        &-line {
          right: 67%;
        }
        @for $i from 1 through 4 {
          &:nth-child(#{$i}) {
            .ourprocess__banner-item-line {
              width: #{(6 + ($i * 3.5)) * 1%};
            }
          }
        }
      }
      &:after {
        left: 0;
      }
    }
  }
}
// max-width: 840px
@include md-lg {
  .ourprocess {
    padding: 55px 0 75px 0;
    &-group a {
      height: 35px;
    }
    &__banner {
      &-item {
        min-height: 120px;
      }
    }
    &--home {
      padding: 80px 0 50px 0;
    }
  }
}
// max-width: 700px
@include sm-md {
  .ourprocess {
    min-height: calc(100vh - 76px);
    min-height: calc(100dvh - 76px);

    &__banner {
      padding: 0;
      gap: 30px;

      &-item {
        padding-left: 25%;
        min-height: 100px;

        &-image {
          left: unset;
          top: 40%;
          right: 100%;
          transform: translate(30%, -50%);
        }
        &-line {
          display: none;
        }
      }
      &:after {
        display: none;
      }
    }
  }
}
// max-width: 570px
@include xsm-sm {
  .ourprocess {
    min-height: calc(100vh - 66px);
    min-height: calc(100dvh - 66px);
    padding: 60px 0;

    &__banner {
      &-item {
        padding-left: 15%;
        min-height: 110px;

        &-image {
          left: unset;
          top: 40%;
          right: 100%;
          transform: translate(10%, -50%);
        }
        &-line {
          display: none;
        }
        &-content {
          gap: 10px;
          h5 {
            font-size: 18px;
            margin-bottom: 0;
          }
          ol {
            gap: 4px 10px;
            li {
              width: calc(50% - 7px);
            }
          }
        }
      }
      &:after {
        display: none;
      }
    }
    &-group {
      flex-wrap: wrap;
      gap: 14px 10px;

      span {
        width: 100%;
      }
      a {
        height: 20px;
      }
    }
    &--home {
      padding: 70px 0 50px 0;
    }
  }
}
