@import "./main/variables.scss";
@import "./main/mixin.scss";
.autocontainer {
  width: 100%;
  max-width: 1210px;
  margin: 0 auto;
  padding: 0 55px;

  &--lg {
    max-width: 1240px;
  }
  &--sm {
    max-width: 1040px;
  }
}
.swiper {
  &-arrow {
    position: relative;
    width: 1em;
    height: 1em;

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;

    background: $white;
    border-radius: 50%;
    text-indent: -999999px;

    transition: 0.3s ease-out;

    &:after {
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;

      border-bottom: 5px solid transparent;
      border-top: 5px solid transparent;
      border-left: 5px solid $black-secondary2;

      transform: translate(-30%, -50%);
    }

    &-prev {
      &:after {
        transform: translate(-70%, -50%) rotate(180deg);
      }
    }

    &:hover {
      box-shadow: 0 0 0 3px rgba($primary, 0.45);
    }
    &:active {
      box-shadow: 0 0 0 4px rgba($primary, 0.2);
      opacity: 0.8;
    }
  }
}
.popup {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.54);
  backdrop-filter: blur(22px);
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  overflow: auto;
  animation-name: fadeIn;
  animation-duration: 0.5s;

  &-close {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    width: 2em;
    height: 2em;
    cursor: pointer;
    @include transition;
    position: absolute;
    top: 13px;
    right: 13px;
    &:hover {
      opacity: 0.7;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__inner {
    background: $white;
    border-radius: 20px;
    // box-shadow: 0px 0px 30px 0px #956df8;
    box-sizing: border-box;
    padding: 40px 30px;
    width: 100%;
    max-width: 820px;
    margin: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 40px;

    &-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 7px;
      padding-bottom: 22px;
      position: relative;
      text-align: center;
      h6 {
        font-weight: 800;
        color: $black;
        &._sm {
          font-weight: 600;
          color: $primary;
        }
      }
      &::after {
        content: "";
        width: calc(100% + 60px);
        height: 2px;
        background: #edeef5;
        position: absolute;
        bottom: 0;
        left: -30px;
      }
      &-ico {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        margin-bottom: 3px;
        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    gap: 60px;
    max-width: 680px;
    width: 100%;
    margin: 0 auto;
    min-height: 240px;

    &-row {
      display: flex;
      flex-wrap: wrap;
      gap: 14px 18px;
    }
    .input {
      &__wrapper {
        width: calc(50% - 9px);
      }
    }
    .buttonPrimary {
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
      font-weight: 400;
    }
    form {
      width: 100%;
    }
  }
}
@include xlg-xxlg {
  .autocontainer {
    padding: 0 45px;
  }
}
@include lg-xlg {
  .autocontainer {
    padding: 0 34px;
  }
}
@include md-lg {
  .autocontainer {
    padding: 0 25px;
  }
}
@include xsm-sm {
  .autocontainer {
    padding: 0 18px;
  }
}
// 480
@include xxsm-xsm {
  .popup {
    &__inner {
      padding: 30px 20px;
      border-radius: 16px;
      gap: 30px;

      &-header {
        &::after {
          width: calc(100% + 40px);
          left: -20px;
        }
      }
    }

    &__form {
      gap: 30px;
      .input {
        &__wrapper {
          width: 100%;
        }
      }
    }
  }
}
