@import "styles/main/variables.scss";
@import "styles/main/mixin.scss";

@mixin hoverEffect {
  transition: 0.2s ease-out;
  transform: scale(1);

  &:hover {
    opacity: 0.8;
    transform: scale(0.98);
  }
}

.infobox {
  min-height: 250px;
  padding: 50px 0;
  background: $primary-0;
  display: flex;

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    gap: 1em;

    color: $white;
    text-align: center;

    h3 {
      color: inherit;
      font-family: $font-livvic;
      font-style: normal;
      font-weight: 700;
      line-height: 1.2;
      text-transform: capitalize;
    }
    p {
      font-family: $font-secondary;
      font-style: normal;
      font-weight: 400;
      line-height: 1.65;
      /* or 167% */
      text-align: center;

      color: #ffffff;
    }
  }
}
.systemBanner {
  position: relative;
  min-height: 100dvh;
  min-height: 100vh;
  padding: 150px 0 150px 0;
  display: flex;
  align-items: center;

  .bg {
    background: $black-secondary;
    &__image {
      height: 70px;
      width: auto;
      img {
        width: auto;
      }
      &:nth-child(1) {
        top: -4px;
        left: calc(50% + 240px);
      }
      &:nth-child(2) {
        top: unset;
        left: unset;
        bottom: -4px;
        right: calc(50% + 240px);
        transform: rotate(180deg);
      }
    }
  }

  &__inner {
    position: relative;
    padding-top: 80px;
    z-index: 0;
    margin: auto 0;

    &-image {
      display: flex;
      justify-content: center;

      & > div {
        position: relative;
        left: 1%;
        width: 85%;
        max-width: 890px;
        height: auto;

        img {
          &._shape {
            width: 100%;
            height: auto;
            object-fit: contain;
          }
          &._logo {
            position: absolute;
            top: 25.9%;
            left: 38.8%;
            width: 17.6%;
            height: auto;
            z-index: 1;
            object-fit: contain;
            transform: rotate(-32deg) translate(0px, 0px) skew(30deg, 2deg);
          }
        }
      }
    }
    .systemBanner {
      &__info {
        position: absolute;
        bottom: 14.75%;
        left: 57.9%;
        width: 40vw;
      }
      &-message {
        z-index: 1;

        &:nth-child(1) {
          bottom: 93%;
          left: calc(51% - 30px);
        }
        &:nth-child(2) {
          bottom: 74.9%;
          left: 17.1%;
        }
        &:nth-child(3) {
          bottom: 75.4%;
          left: 79.5%;
        }
      }
    }
  }
  &__info {
    position: relative;
    padding-top: 12px;
    z-index: 2;
    max-width: 510px;

    &-body {
      position: relative;
      display: flex;
      &:after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 90%;
        height: 100%;
        z-index: -1;
        background: $gradient2;
        border-radius: 5px;
      }
      &:before {
        position: absolute;
        content: "";
        top: calc(100% - 2px);
        left: 12px;
        width: 0;
        height: 0;
        border-left: 13px solid $mint2;
        border-right: 13px solid transparent;
        border-top: 12px solid $mint2;
        border-bottom: 12px solid transparent;
      }
    }
    &-title {
      padding: 10px 10px 10px 12px;

      color: $white;
      font-family: $font-poppins;
      font-style: normal;
      font-weight: 700;
      font-size: 19px;
      line-height: 1;
      text-transform: uppercase;
      text-align: center;
      writing-mode: vertical-lr;
      transform: rotate(180deg);
    }
    &-content {
      position: relative;
      padding: 14px 25px 14px 16px;
      background: #ffffff;
      border-radius: 5px;
      transform: translateY(-12px);

      p {
        //font
        color: $black;
        font-family: $font-poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.25;
      }

      &:after {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        width: 16px;
        height: 100%;

        background: linear-gradient(180deg, #6bf5f6 0%, #a057f8 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
      }
    }
  }
  &-message {
    position: absolute;
    width: auto;
    max-width: 240px;

    padding: 12px 12px 15px 12px;
    background: $primary;
    border-radius: 5px;

    // font
    color: $white;
    font-family: $font-poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 1.14;
    text-align: center;
    text-transform: uppercase;

    &:after {
      position: absolute;
      content: "";
      top: calc(100% - 2px);
      left: 15px;
      width: 0;
      height: 0;
      border-left: 15px solid $primary;
      border-right: 15px solid transparent;
      border-top: 13px solid $primary;
      border-bottom: 13px solid transparent;
    }
  }
}
.privilegies {
  position: relative;
  z-index: 0;
  overflow: hidden;

  &__inner {
    max-width: 880px;
    margin: 0 auto;
    width: 100%;

    &-title {
      position: relative;
      padding: 80px 0 20px;
      z-index: 1;

      //font
      color: $black-2;
      text-align: center;

      h3 {
        color: inherit;
        font-family: $font-livvic;
        font-style: normal;
        font-weight: 700;
        line-height: 1.25;
      }
      h5 {
        color: inherit;
        font-family: $font-secondary;
        font-style: normal;
        font-weight: 700;
        line-height: 1.25;
        text-transform: uppercase;
      }
    }
  }
  &Graph {
    position: relative;
    margin-top: -180px;
    z-index: 0;

    &-bg {
      display: flex;
      position: relative;
      width: 100%;
      z-index: -1;

      img {
        width: 100%;
        height: auto;
      }
    }
    &__column {
      position: absolute;
      display: flex;
      flex-direction: column;
      gap: 10px;
      max-width: 245px;
      z-index: 2;

      &:nth-child(1) {
        bottom: 27%;
        left: 0;
      }
      &:nth-child(2) {
        right: 0;
        bottom: 21.9%;
      }

      &-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.5em;

        background: $white;
        box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.9);
        border-radius: 5px;
        padding: 10px 12px;
        min-height: 110px;

        strong {
          color: $primary-0;
          font-family: $font-primary;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 1.2;
          text-align: center;
        }
        span {
          color: $gray;
          font-family: $font-chivo;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 1.12;
          text-align: center;
          letter-spacing: 0.02em;
          text-transform: capitalize;
        }
        ul {
          display: flex;
          flex-direction: column;
          gap: 0;
          li {
            display: flex;
            align-items: center;

            &::before {
              content: "";
              display: inline-block;
              width: 0.5em;
              height: 0.5em;
              margin-right: 0.5em;
              background: $primary-0;
              border-radius: 50%;
            }

            span {
              line-height: 1.3;
            }
          }
        }
      }
    }
    &__joints {
      position: absolute;
      width: 80%;
      height: 33%;
      left: 10%;
      bottom: 19%;
      z-index: 1;
      //display
      display: flex;
      flex-direction: column;

      &-item {
        position: relative;
        height: 25%;

        & > div {
          display: flex;
          align-items: center;
          height: 36%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-100%, -50%);

          ._line {
            height: 100%;
            width: auto;
          }
          ._circle {
            position: absolute;
            top: 100%;
            left: 100%;
            transform: translate(-57%, -56%);
          }
        }
        &:nth-child(even) {
          & > div {
            transform: scaleX(-1) translate(0, -50%);
          }
        }
      }
    }
    &-group {
      display: flex;
      align-items: center;
      gap: 0.75em;

      img {
        height: 28px;
        width: auto;
      }
    }
  }
  .bg {
    z-index: -2;
  }
}
.success {
  position: relative;
  z-index: 0;
  padding: 175px 0;

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 110px;
  }
  &__title {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 50px;

    h3 {
      color: inherit;
      font-family: $font-livvic;
      font-style: normal;
      font-weight: 700;
      line-height: 1.2;
      text-transform: capitalize;
    }
    h5 {
      color: inherit;
      font-family: $font-secondary;
      font-style: normal;
      font-weight: 700;
      line-height: 1.2;
      text-transform: uppercase;
    }
  }
  .bg {
    background: $black-secondary;
    &__image {
      height: auto;
      width: 60px;
      img {
        height: auto;
        width: 100%;
      }

      &:nth-child(1) {
        height: 70px;
        top: -1px;
        width: auto;
        img {
          height: 100%;
          width: auto;
        }
        left: calc(50% + 260px);
      }
      &:nth-child(2) {
        top: unset;
        bottom: -1px;
        left: -1px;
      }
      &:nth-child(3) {
        top: unset;
        left: unset;
        bottom: -1px;
        right: -1px;

        img {
          transform: rotate(180deg);
        }
      }
    }
  }

  &Knowledge {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    transform: scale(1.15);
    padding: 5% 0;

    &__label {
      display: flex;
      justify-content: center;
      padding: 6px;
      position: relative;
      border-radius: 30px;
      border: 7px solid $primary;

      &-image {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        background: $white-0;
        padding: 16px;
        min-width: 250px;

        img {
          height: 60px;
          width: auto;
        }
      }
    }
    &__row {
      position: relative;
      display: flex;
      justify-content: center;
      gap: 30px;
      padding-top: 100px;

      &-lines {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 110px;

        img {
          height: 100%;
          width: auto;
        }
      }
    }
    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 15px;

      background: $white-0;
      border-radius: 20px;
      box-shadow: 12px 20px 22px rgba(0, 0, 0, 0.25);
      overflow: hidden;
      height: 100%;

      &-wrapper {
        position: relative;
        z-index: 0;
        padding: 13px 20px;
        width: 220px;
        height: 190px;
      }
      &-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;

        svg,
        img {
          width: 100%;
          height: 100%;
        }
      }
      &-icon {
        width: 45px;
        height: 45px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      &-group {
        display: flex;
        flex-direction: column;

        //font
        font-family: $font-chivo;
        color: $black;
        font-style: normal;
        font-weight: 900;
        font-size: 36px;
        line-height: 1.2em;
        text-align: center;
        text-transform: capitalize;

        strong {
          font-size: inherit;
          font-weight: inherit;
          line-height: inherit;
          &._sm {
            font-size: 0.8em;
          }
        }
        small {
          font-family: inherit;
          font-weight: inherit;
          line-height: 1.2;
          color: inherit;
          font-size: 16px;
        }
      }
    }
  }
  &Statistics {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 30px;

    &__title {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      color: $white;
      h4 {
        color: inherit;
        font-family: $font-livvic;
        font-style: normal;
        font-weight: 700;
        line-height: 1.2;
        text-transform: capitalize;
      }
    }
    &__group {
      position: relative;
      &-wrapper {
        padding: 0 30px;
      }
      &-bg {
        display: flex;
        img {
          width: 100%;
          height: auto;
        }
      }
      &-row {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      &-column {
        position: absolute;
        width: 20.5%;
        top: 49.5%;
        transform: translate(-50%, -50%);

        &:nth-child(1) {
          left: 14.2%;
        }
        &:nth-child(2) {
          left: 49.5%;
        }
        &:nth-child(3) {
          left: 84.9%;
        }
      }
    }
    &__item {
      position: relative;
      padding-bottom: 98%;

      &-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: $white;
        overflow: hidden;
        ///display
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0;

        //font
        color: $primary-0;
        font-family: $font-chivo;
        font-style: normal;
        font-weight: 900;
        font-size: 50px;
        line-height: 1.1;
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: capitalize;
        text-align: center;

        strong {
          font-size: 1em;
          font-weight: inherit;
          line-height: 1;
        }
        small {
          font-size: 16px;
          font-weight: 400;
        }

        &._lg {
          strong {
            font-size: 1.6em;
          }
        }
        &._sm {
          strong {
            font-size: 0.8em;
          }
        }
        &._muted {
          color: $gray-2;
        }
      }
    }
  }
}
.caseStudy {
  position: relative;
  padding: 130px 0 80px 0;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    &-title {
      color: $black-2;
      font-family: $font-livvic;
      font-style: normal;
      font-weight: 700;
      line-height: 1.2;
      text-align: center;
      text-transform: capitalize;
    }
    &-banner {
      width: 100%;
      max-width: 875px;
    }
  }

  &__banner {
    position: relative;
    padding-bottom: 60%;
    z-index: 0;

    &-bg {
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      z-index: -1;

      img {
        width: 100%;
        height: 100%;
      }
    }
    img {
      position: absolute;

      &.man {
        left: 50%;
        bottom: 0;
        width: 40%;
        transform: translateX(-50%);
      }
      &.item {
        height: auto;
        border-radius: 15px;
        overflow: hidden;
        box-shadow: 0px 4px 45px 0px rgba(0, 0, 0, 0.25);
      }
      &.item1 {
        left: 0;
        top: 21%;
        width: 33%;
      }
      &.item2 {
        top: 73%;
        left: 8%;
        width: 21%;
      }
      &.item3 {
        top: 73%;
        left: 75%;
        width: 30%;
      }
      &.item4 {
        top: 24%;
        left: 66%;
        width: 28.5%;
      }
    }
  }

  .bg {
    &__image {
      height: 60px;
      width: auto;
      left: calc(50% + 260px);
      top: -1px;
      img {
        height: 100%;
        width: auto;
      }
    }
  }
}

@media (min-width: 1240px) {
  .systemBanner__inner .systemBanner-message:nth-child(3) {
    max-width: unset;
    width: 260px;
  }
}
@include xlg-xxlg {
  .systemBanner {
    &__inner {
      .systemBanner-message:nth-child(3) {
        bottom: 72.4%;
        left: 76.5%;
      }
    }
  }
}
@include lg-xlg {
  .systemBanner {
    .bg {
      &__image {
        height: 50px;
      }
    }
    &__info {
      &-title {
        font-size: 16px;
      }
    }
    &__inner {
      .systemBanner {
        &__info {
          left: 52%;
          width: 45vw;
        }
      }
    }
    &-message {
      font-size: 16px;
    }
  }
  .success {
    .bg {
      &__image {
        height: auto;
        width: 50px;

        &:nth-child(1) {
          height: 50px;
          width: auto;
        }
      }
    }
    &Knowledge {
      transform: unset;
      padding: unset;
      &__label {
        img {
          height: 75px;
        }
      }
      &__row {
        gap: 15px;
        padding-top: 90px;
        &-lines {
          height: 100px;
        }
      }
    }
    &Statistics {
      max-width: 700px;

      &__item {
        &-content {
          font-size: 42px;
        }
      }
    }
  }
  .caseStudy {
    padding-top: 100px;
    &__inner {
      &-banner {
        width: 80%;
        max-width: 700px;
      }
    }
  }
}
@include md-lg {
  .systemBanner {
    padding: 80px 0 100px 0;

    &__inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;

      &-image {
        img {
          width: 60%;
          max-width: 600px;
        }
      }
      .systemBanner {
        &__info {
          position: unset;
          order: 1;
          width: 90%;
          &-body {
            &:before {
              display: none;
            }
          }
        }
        &-message {
          &::after {
            display: none;
          }
          &:nth-child(1) {
            left: 50%;
            transform: translateX(-50%);
          }
          &:nth-child(2) {
            left: 0;
            bottom: 75.5%;
          }
          &:nth-child(3) {
            left: unset;
            right: 0;
            bottom: 77%;
          }
        }
      }
    }
    .bg {
      &__image {
        height: 40px;

        &:nth-child(1) {
          left: 50%;
        }
        &:nth-child(2) {
          right: 50%;
        }
      }
    }
  }
  .privilegies {
    padding: 60px 0;

    .bg {
      display: none;
    }
    &__inner {
      display: flex;
      flex-direction: column;
      gap: 40px;
      &-title {
        padding: 0;
      }
    }
    &Graph {
      margin: 0;

      &__columns {
        display: flex;
        flex-direction: column;
        gap: 25px;
      }
      &__column {
        position: unset;
        gap: inherit;
        width: 100%;
        max-width: unset;

        &-list {
          gap: 1.5em;
          background: $white;
          box-shadow: 0px 4px 45px 0px rgba($black-secondary, 0.15);
          border-radius: 15px;
          padding: 20px 25px;

          strong {
            font-size: 22px;
          }
          span {
            font-size: 18px;
          }
          ul {
            gap: 0.5em;
            li {
              font-size: 18px;

              &::before {
                content: "";
                display: inline-block;
                width: 0.5em;
                height: 0.5em;
                margin-right: 1em;
                background: $primary-0;
                border-radius: 50%;
              }

              span {
                line-height: 1.3;
              }
            }
          }
        }
      }
      &__joints {
        display: none;
      }
      &-bg {
        display: none;
      }
    }
  }
  .success {
    padding: 100px 0;
    .bg {
      &__image {
        display: none;

        &:nth-child(1) {
          display: block;
          height: 30px;
          left: 50%;
        }
      }
    }
    &Knowledge {
      gap: 40px;

      &__row {
        width: 100%;
        padding-top: 0;
        gap: 25px 15px;
        flex-wrap: wrap;
        justify-content: space-evenly;
        &-lines {
          display: none;
        }
      }
      &__item {
        &-wrapper {
          width: calc(50% - 10px);
          max-width: 290px;
          height: 240px;
          padding: 15px 25px;
        }
      }
    }
    &Statistics {
      max-width: 700px;
      &__group {
        &-wrapper {
          padding: 0;
        }
      }
      &__item {
        &-content {
          font-size: 36px;
          small {
            font-size: 14px;
          }
        }
      }
    }
  }
  .infobox {
    padding: 40px 0;
  }
}
@include sm-md {
  .infobox {
    p {
      br {
        display: none;
      }
    }
  }
}
@include xsm-sm {
  .systemBanner {
    padding: 60px 0;
    min-height: 85vh;

    &-message {
      max-width: 180px;
      font-size: 12px;
      font-weight: 500;
    }
    &__inner {
      padding-top: 55px;

      .systemBanner__info {
        width: 95%;
      }
    }
  }
  .success {
    &__title {
      gap: 10px;
      h5 {
        font-size: 16px;
        font-weight: 400;
      }
    }
    &Knowledge {
      &__label {
        border-width: 5px;
        
        &-image {
          min-width: 200px;
          img {
            height: 55px;
            width: auto;
          }
        }
      }
      gap: 30px;
      &__row {
        gap: 15px;
      }
      &__item {
        &-wrapper {
          width: 100%;
          max-width: 260px;
          height: 220px;
        }
      }
    }
    &Statistics {
      gap: 20px;
      &__item {
        &-content {
          font-size: 26px;
        }
      }
      &__title {
        h4 {
          font-size: 16px;
        }
      }
    }
  }
  .caseStudy {
    padding-top: 70px;
    &__inner {
      &-banner {
        width: 95%;
      }
    }
    &__banner {
      padding-bottom: 70%;
      &-bg {
        height: 90%;
      }
      img {
        &.item {
          z-index: 1;
          border-radius: 8px;
        }
        &.man {
          width: 60%;
        }
        &.item1 {
          top: 3%;
          width: 40%;
        }
        &.item2 {
          top: unset;
          bottom: 5%;
          left: -2%;
          width: 35%;
        }
        &.item3 {
          top: 60%;
          width: 45%;
          left: 58%;
        }
        &.item4 {
          top: 13%;
          width: 46%;
          left: 58%;
        }
      }
    }
  }
}
@include xxsm-xsm {
  .success {
    &Statistics {
      &__item {
        &-content {
          small {
            font-size: 10px;
          }
        }
      }
    }
  }
  .systemBanner {
    &__inner {
      padding-top: 45px;
    }
    &-message {
      max-width: 150px;
      padding: 10px 6px;
    }
    &__info {
      &-title {
        font-size: 14px;
        padding: 10px 7px;
      }
      &-content {
        padding: 10px;
        p {
          font-size: 13px;
          line-height: 1.2;
        }
        &:after {
          width: 5px;
        }
      }
    }
  }
  .infobox {
    padding: 35px 0;
  }
}
