/* Typography */
h1 {
  font-size: 50px;
}
h2 {
  font-size: 50px;
}
h3 {
  font-size: 40px;
}
h4 {
  font-size: 34px;
}
h5 {
  font-size: 26px;
  &._lg {
    font-size: 28px;
  }
  &._sm {
    font-size: 24px;
  }
}
h6 {
  font-size: 20px;
  &._lg {
    font-size: 22px;
  }
  &._sm {
    font-size: 18px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-primary;
  font-weight: 700;
  line-height: 1.2em;
}

p {
  font-size: 16px;
  line-height: 1.6;
  &._lg {
    font-size: 18px;
  }
  &._xlg {
    font-size: 20px;
  }
}

@include xxlg-xxxlg {
  h1 {
    font-size: 46px;
  }
  h2 {
    font-size: 46px;
  }
  h3 {
    font-size: 38px;
  }
}
@include xlg-xxlg {
  h1 {
    font-size: 38px;
  }
  h2 {
    font-size: 38px;
  }
  h3 {
    font-size: 34px;
  }
  h4 {
    font-size: 32px;
  }
}
@include lg-xlg {
  h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 36px;
  }
  h3 {
    font-size: 32px;
  }
  h4 {
    font-size: 28px;
  }
  h5 {
    font-size: 24px;
    &._lg {
      font-size: 26px;
    }
    &._sm {
      font-size: 22px;
    }
  }
  h6 {
    font-size: 18px;
    &._lg {
      font-size: 20px;
    }
  }
  p {
    line-height: 1.5;
    &._xlg {
      font-size: 18px;
    }
  }
}
@include md-lg {
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 32px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 20px;
    &._lg {
      font-size: 22px;
    }
    &._sm {
      font-size: 20px;
    }
  }
  h6 {
    &._lg {
      font-size: 18px;
    }
  }
  p {
    line-height: 1.5;
    &._lg {
      font-size: 16px;
    }
  }
}
